.dialer-page {
  height: 100%;

  .dial-banner {
    height: 27%;

    .banner-img {
      width: 100%;
      max-height: 100%;
      background-size: 100% 100% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      height: 100%;
    }
   
  }
 
.dial-buttons-heading{
  font-size: 18px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  font-weight: 600;
  padding-top: 10px;
}
  .dial-buttons {
    height: 8%;

    ion-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 60%;
    --background-color:red;
    }
  }

  .info-text {
    height: 15%;
    color: lightgrey;
  }

  .center,
  .ion-text-center {
    text-align: center !important;
    // color: lightgray;
    font-weight: bold !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .ml-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .mt-5 {
    margin-top: 5 !important;
  }
}

.round-button {
  min-width: 48 !important;
  max-width: 120px !important;
  min-height: 48 !important;
  max-height: 120px !important;
  text-decoration: none !important;
  display: inline-block !important;
  outline: none !important;
  cursor: pointer !important;
  border-style: none !important;
  // color: white !important;
  background-color: white !important;
  border-radius: 20% !important;
  overflow: none !important;
  text-align: center !important;

  // padding: 0 !important;
  // margin-left: 10%;
  // margin-bottom: 10%;
  ion-icon {
    color: black;
    font-size: 3rem;
    vertical-align: middle;
  }

}

.h-30 {
  height: 30% !important;
}

.center-div {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: bold;

  .main-content {
    color: lightgrey !important;
  }
}

.flex-column {
  flex-direction: column !important;
}

.height-control {
  height: 26.5% !important;
}

.display {
  font-weight: normal !important;
}

.lable-m {
  margin: 0 !important;
  margin-top: 5px !important;
}

.btn-call-col {
  --background: transparent !important;
  background: transparent !important;

  ion-button {
    box-shadow: none !important;
  }
}

.btn-disconnect-col {
  --background: transparent !important;
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  ion-button {
    box-shadow: none !important;
  }
}

.star-key {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  // font-size: 12vw !important;
}

.dial-back-btn {
  font-size: 5vw !important;
  --padding: 0 !important;
  --vertical-align: middle;
  --padding-start: 0px;
  --padding-end: 0px;
  color: rgb(0, 40, 86) !important;
  --ripple-color: black;
}

.audio-option {
  position: absolute;
  width: 30% !important;
  right: 0;
  border: none !important;
  box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px !important;
  background: #ffffff !important;
  text-decoration: none !important;
  --background-activated: #ffffffb9 !important;
  --background: white !important;
  // color: lightgray;
}

.relative {
  position: relative !important;
}

.w-100 {
  width: 100% !important;
}

//  New Added css
.phoneSection {
  .relative {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }

  .header {
    position: absolute;
    top: 25%;
    width: 100%;

  }

  .phoneCenterSection {
    position: absolute;
    top: 50%;
    width: 100%;
  }

  border: 1px;
  position: fixed;
  background: #51b7e0d1;
  z-index: 99;
  height: 100%;
  top: 0;
  right: 0;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 0;
  bottom: 0;

  .subTitle {
    font-size: 10px;
  }

  .phoneCenterSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lockClosedIcon {
    width: 50px;
    height: 50px;
    border: 1px solid;
    background: #000;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon {
      font-size: 26px;
      color: #fff;
    }
  }


}

.dial-icons {
  height: 40px;
}

.dial-icon-arrow {
  position: relative;

  .arrow-icons {
    position: absolute;
    height: 15px;
    top: calc(100% - 15px);
    position: absolute;
    right: -25px;
    // filter: invert(11%) sepia(77%) saturate(1881%) hue-rotate(195deg) brightness(97%) contrast(103%);
  }
}

.callEndIcon {
  --ion-color-base: red !important;
  width: 50px;
  height: 50px;
  background: red;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  ion-icon {
    font-size: 26px;
    color: #fff !important;
  }
}

.call-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
