@import 'swiper/swiper-bundle';
ion-content,
ion-item {
  --background: #ffff;
  --overflow: scroll !important;
}

ion-header {
  ion-toolbar {
   
    --background: #002856;
    text-align: center !important;
  }
}

ion-footer {
  ion-button {
    margin: auto !important;
  }
  ion-icon {
    color: white !important;
  }
  ion-toolbar {
   
    --background: #002856;
    text-align: center !important;
  }
}

.btn {
  --background: white;
  color: var(--text);
  // border: solid #e8e8e8 2px !important;
  --background-activated: var(--active-button) !important;
  --border-radius: 5px !important;
}

.shadow {
  -webkit-box-shadow: 0px 0px 21px #e6e6e6 !important;
  -moz-box-shadow: 0px 0px 21px #e6e6e6 !important;
  box-shadow: 0px 0px 21px #e6e6e6 !important;
  --box-shadow: 0px 14px 25px #c2c2c2 !important;
}

.center {
  display: block !important;
  margin: auto !important;
  width: 50% !important;
}
.fs-10 {
    font-size: 10px !important;
}
.fs-12 {
    font-size: 12px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-16 {
    font-size: 16px !important;
}
.fs-18 {
    font-size: 18px !important;
}
.fs-20 {
    font-size: 20px !important;
}
.fs-22 {
    font-size: 22px !important;
}
.fs-30 {
    font-size: 30px !important;
}
.fs-40 {
    font-size: 40px !important;
}
.fs-50 {
    font-size: 50px !important;
}
.fs-60 {
    font-size: 60px !important;
}
.bottom-buttons {
  position: fixed;
  bottom: 100px;
  width: 100% !important;
}


h1 {
  color: rgb(0, 0, 0) !important;
}

h2 {
  color: rgb(0, 0, 0) !important;
}

h3 {
  color: rgb(0, 0, 0) !important;
}

h4 {
  color: rgb(0, 0, 0) !important;
}


p {
  color: var(--text) !important;
}

h1 {
  color: rgb(0, 0, 0);
}

h2 {
  color: rgb(0, 0, 0);
}

h3 {
  color: rgb(0, 0, 0);
}

h4 {
  color: rgb(0, 0, 0);
}

p {
  color: var(--text);
}

.form-container {
  // margin-top: 40%;
  // margin-left: 20%;
  // margin-right: 20%;
  color: white;
  ion-input {
    color: black;
    padding-left: 10px !important;
    font-size: 0.9em;
    border: 1px solid #000000 !important;
    --background: rgb(255, 255, 255) !important;
  }
  ion-button {
    color: #000000 !important;
  }

  h1 {
    color: white !important;
  }

}



// @media only screen and (max-width: 576px) {
//   .hidden-md {
//     display: block !important;
//   }
//   .hidden-xs, .hidden {
//       display: none !important;
//   }
// }

// @media only screen and (min-width: 576px) {
//   .hidden-xs {
//     display: block !important;
//   }
//   .hidden-md, .hidden {
//       display: none !important;
//   }
// }